html {
  margin: 0;
  padding: 0;
}

@media (max-width: 500px) {
  .homeGrid {
    grid-template-columns: repeat(1, 1fr);
  }
  .grid {
    grid-template-columns: repeat(3, 25vw);
  }
}

@media (min-width: 501px) {
  .homeGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}



@media (min-width: 901px) {
  html {
    font-size: 18px;
    border: solid 20px
  #212529;
  border-top: none;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form {z-index: 5;}

button {
  background-color: #009900;
  color: white;
  /* border-radius: 3px; */
  border: none;
  padding: 10px 20px 10px 20px;
}

.img {
  max-width: 100%;
  max-height: 500px;
  /* object-fit: contain; */
} 

.img2{
  width: 100%;
}

.text {
  text-align: center;
  margin-left: 2em;
  margin-right: 2em;
}

h4 {
  color: #009900;
  text-decoration: underline;
}

.centerChildren {
  display: flex;
  flex-direction: column;
}

.centerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  
}

.centerChildren h1, h2, h4 {
  align-self: center;
}

.videoMain {
  width: 100%;
  height: 80vh;
}
  
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.videoOverlay {
  position: absolute;
  width: 100%;
  height: 80vh;
  background-color: rgba(0,0,0,.5);
}
  
  .largeDisplay {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

.content {
  position: absolute;
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.logo {
  height: 34px;
}

.grid {
  display: grid;
  max-width: 100vw;
  grid-auto-rows: 20vh;
  grid-gap: .5em;
  font-size: 2rem;
  /* overflow-wrap: break-word;
  overflow: hidden; */
  margin: 1em;
}

.homeGrid {
  display: grid;
  grid-gap: .5em;
  grid-auto-rows: 60vh;
}

.homeGridBottom {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding-top: .5em;
  grid-gap: 1em;
  grid-auto-rows: 40vh;
}

.grid a, .homeGrid a, .homeGridBottom a{
  background-size: cover;
  color: white;
  /* font-size: 40px; */
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
}

.brighten {
  /* -webkit-filter: grayscale(0%); */
  filter: brightness(100%);
  transition: all 1s ease;
  }
  
  .brighten:hover {
  /* -webkit-filter: grayscale(100%); */
  filter: brightness(70%);
  }

  .overlay {
    top: 0;
    bottom: 0;
    background: rgba(0, 70, 0, 0.7); /* Black see-through */
    height: 100%;
    width: 100%;
    opacity:1;
    color: white;
    font-size: 2rem;
    overflow-wrap: break-word;
    padding: 20px;
    text-align: center;
  }
  
  .overlayRed {
    top: 0;
    bottom: 0;
    /* background: rgba(200, 41, 29, 0.7); */
    height: 100%;
    width: 100%;
    opacity:1;
    color: white;
    font-size: 2rem;
    overflow-wrap: break-word;
    padding: 20px;
    text-align: center;
  }



.border {
  border-top: solid 1px rgb(29, 28, 28);
  margin-bottom: 15px;
}